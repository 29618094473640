import { del, get, post, put } from "./api_helper"
import * as url from "./url_helper"

export const LoginUser = data => post(url.LOGIN, data)

export const GetUsers = _ => get(url.USERS)

export const GetSearchLogs = _ => get("/searchlogs")

export const UpdateUserStatus = data => post(url.UPDATE_USER_STATUS, data)

export const UpdateUser = data => put("/usersAdmin/users", data)

export const SendUserMail = id => post("/usersAdmin/sendUserMail/" + id)

export const GetCompanyByID = id => get(url.COMPANIES + "/" + id)

export const AdminLoginUser = userID => post("/usersAdmin/login/" + userID)
