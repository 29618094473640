import React, { useEffect, useState } from "react"
import { Card, CardBody, Col, Container, Row } from "reactstrap"
import paginationFactory, {
  PaginationProvider,
  PaginationListStandalone,
} from "react-bootstrap-table2-paginator"
import BootstrapTable from "react-bootstrap-table-next"
import ToolkitProvider, {
  Search,
  CSVExport,
} from "react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit"
import "flatpickr/dist/themes/material_blue.css"

import { formatDate } from "helpers/misc_helpers"
import { useLoading } from "helpers/custom_hooks"
import { GetSearchLogs } from "helpers/backend_helper"
const { SearchBar } = Search
const { ExportCSVButton } = CSVExport

const Searches = props => {
  const node = React.createRef()
  const setLoading = useLoading()
  const [data, setData] = useState([])

  const pageOptions = {
    sizePerPage: 100,
    totalSize: data.length,
    custom: true,
  }

  const columns = [
    {
      dataField: "searchTerm",
      text: "Search Term",
      sort: true,
    },
    {
      dataField: "resultsCount",
      text: "Num. of results",
      sort: true,
    },
    {
      dataField: "timestamp",
      text: "Date",
      sort: true,
      formatter: cell => <>{formatDate(cell)}</>,
      headerStyle: _ => ({
        textAlign: "center",
      }),
      align: () => "center",
    },
    {
      dataField: "username",
      text: "User",
      sort: true,
    },
    {
      dataField: "name",
      text: "Company",
      sort: true,
    },
  ]

  useEffect(() => {
    loadData()
  }, [])

  const loadData = async () => {
    setLoading(true)
    let result = await GetSearchLogs()

    setData(result)
    setLoading(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row>
            <Col lg="12"></Col>
            <Card>
              <CardBody>
                <PaginationProvider
                  pagination={paginationFactory(pageOptions || [])}
                  keyField="id"
                  columns={columns || []}
                  data={data || []}
                >
                  {({ paginationProps, paginationTableProps }) => (
                    <ToolkitProvider
                      keyField="id"
                      data={data}
                      columns={columns || []}
                      remote={{ filter: true }}
                      bootstrap4
                      search
                      exportCSV
                    >
                      {toolkitProps => (
                        <React.Fragment>
                          <Row>
                            <Col>
                              {" "}
                              <h4 className="mb-3">Searchlog</h4>
                            </Col>
                            <Col className="text-end">
                              <ExportCSVButton
                                className="btn btn-primary me-3"
                                {...toolkitProps.csvProps}
                              >
                                Export CSV!!
                              </ExportCSVButton>
                              <SearchBar {...toolkitProps.searchProps} />
                            </Col>
                          </Row>

                          <div className="table-responsive">
                            <BootstrapTable
                              {...toolkitProps.baseProps}
                              {...paginationTableProps}
                              bordered={false}
                              hover={true}
                              classes={
                                "table align-middle table-nowrap table-check"
                              }
                              headerWrapperClasses={"table-light"}
                              ref={node}
                            />
                            {data.length == 0 && (
                              <p className="text-center">
                                There are currently no searches!
                              </p>
                            )}
                          </div>
                          <div className="pagination pagination-rounded justify-content-end mb-2">
                            <PaginationListStandalone {...paginationProps} />
                          </div>
                        </React.Fragment>
                      )}
                    </ToolkitProvider>
                  )}
                </PaginationProvider>
              </CardBody>
            </Card>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Searches
