import React from "react"
import { Navigate } from "react-router-dom"
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Dashboard from "../pages/Dashboard/index"
import Pages404 from "pages/Util/pages-404"
import Users from "pages/Users"
import Searches from "pages/Searches"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/users", component: <Users /> },
  { path: "/searches", component: <Searches /> },
  {
    path: "/",
    exact: true,
    component: <Navigate to="/users" />,
  },
]

const publicRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "*", component: <Pages404 /> },
]

export { authProtectedRoutes, publicRoutes }
