import React, { useEffect, useState } from "react"
import {
  Card,
  CardBody,
  Col,
  Label,
  Modal,
  Row,
  Spinner,
  Table,
} from "reactstrap"
import defaultAvatar from "../../assets/images/default-avatar.jpeg"
import moment from "moment"
import {
  GetCompanyByID,
  SendUserMail,
  UpdateUser,
} from "helpers/backend_helper"
import ConfirmationModal from "./ConfirmationModal"

const UserEditModal = ({ modal, close, user }) => {
  const [loading, setLoading] = useState(false)
  const [sendMailModal, setSendMailModal] = useState(false)
  const [company, setCompany] = useState()
  const [reqObj, setReqObj] = useState(user)
  console.log(user)
  useEffect(() => {
    if (user) loadData()
  }, [user])

  const loadData = async () => {
    try {
      setLoading(true)
      const result = await GetCompanyByID(user.companyID)
      setCompany(result)
    } catch (error) {
      console.log(error)
      alert("An unexpected error happened!")
    }
    setLoading(false)
  }

  const handleSave = async () => {
    setLoading(true)

    try {
      await UpdateUser(reqObj)
      setLoading(false)
    } catch (error) {
      alert("An unexpected error happened!")
      console.log(error)
    }
  }

  const handleSendMail = async () => {
    setLoading(true)

    try {
      setSendMailModal(false)
      await SendUserMail(reqObj.id)
      setLoading(false)
      alert("Email sent!")
    } catch (error) {
      alert("An unexpected error happened!")
      console.log(error)
    }
  }

  useEffect(() => {
    setReqObj(user)
  }, [user])
  return (
    <Modal isOpen={modal} toggle={close}>
      <>
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Company Info
          </h5>
          <button
            type="button"
            onClick={close}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <Card>
            <CardBody>
              {loading ? (
                <div className="w-100 text-center">
                  <Spinner color="dark" />
                </div>
              ) : (
                <>
                  <Row>
                    <Col lg="3">
                      <img
                        src={company?.logoURL ?? defaultAvatar}
                        alt=""
                        className="img-thumbnail mt-3"
                        style={{
                          width: "90px",
                          height: "90px",
                          objectFit: "cover",
                          borderRadius: "15%",
                        }}
                      />
                    </Col>

                    <Col>
                      <h5 className="mt-2 mb-1">{company?.name}</h5>
                      <p className="text-muted mb-1">
                        {company?.street}
                        <br />
                        {company?.postalCode} {company?.city}
                        <br />
                        {company?.country} - {company?.region}
                        <br />
                        {company?.vatNumber}
                      </p>
                      <p className="text-muted">
                        Scope of business: <br /> {company?.serviceActivity}
                      </p>
                      <p className="text-muted mb-1">
                        Member since:{" "}
                        {moment(company?.createdAt).format("DD.MM.YYYYY")}
                      </p>
                    </Col>
                  </Row>

                  <Row className="mt-2 ">
                    <Col>
                      <div className="">
                        <Label className="control-label">Status:</Label>

                        <select
                          className="form-control form-sm select2"
                          value={reqObj?.status ?? ""}
                          onChange={e =>
                            setReqObj({
                              ...reqObj,
                              status: e.target.value,
                            })
                          }
                        >
                          <option disabled value="">
                            -
                          </option>
                          <option value="active">active</option>
                          <option value="pending payment">
                            pending payment
                          </option>
                          <option value="inactive">inactive</option>
                        </select>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-2 ">
                    <Col>
                      <div className="">
                        <Label className="control-label">Account Type:</Label>

                        <select
                          className="form-control form-sm select2"
                          value={reqObj?.accountType ?? ""}
                          onChange={e =>
                            setReqObj({
                              ...reqObj,
                              accountType: e.target.value,
                            })
                          }
                        >
                          <option disabled value="">
                            -
                          </option>
                          <option value="Standard">Standard</option>
                          <option value="Premium">Premium</option>
                        </select>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-2 ">
                    <Col>
                      <div className="">
                        <Label className="control-label">QC Module:</Label>

                        <select
                          className="form-control form-sm select2"
                          value={reqObj?.qcModule ?? ""}
                          onChange={e =>
                            setReqObj({
                              ...reqObj,
                              qcModule: e.target.value,
                            })
                          }
                        >
                          <option value="0">No</option>
                          <option value="1">Yes</option>
                        </select>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-2 ">
                    <Col>
                      <div className="">
                        <Label className="control-label">Billing Period:</Label>

                        <select
                          className="form-control form-sm select2"
                          value={reqObj?.billingPeriod}
                          onChange={e =>
                            setReqObj({
                              ...reqObj,
                              billingPeriod: e.target.value,
                            })
                          }
                        >
                          <option value="anual">Anual</option>
                          <option value="half-yearly">Half-Yearly</option>
                        </select>
                      </div>
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col>
                      <Label className="control-label">
                        Last Invoice Date:
                      </Label>

                      <input
                        className="form-control"
                        type="date"
                        value={moment(reqObj?.lastInvoice).format("YYYY-MM-DD")}
                        onChange={e =>
                          setReqObj({ ...reqObj, lastInvoice: e.target.value })
                        }
                      />
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col>
                      <Label className="control-label">Trial until:</Label>

                      <input
                        className="form-control"
                        type="date"
                        value={moment(reqObj?.trialUntil).format("YYYY-MM-DD")}
                        onChange={e =>
                          setReqObj({ ...reqObj, trialUntil: e.target.value })
                        }
                      />
                    </Col>
                  </Row>

                  <Row className="mt-2">
                    <Col>
                      <Label className="control-label">Note:</Label>

                      <input
                        className="form-control"
                        type="text"
                        value={reqObj?.note ?? ""}
                        onChange={e =>
                          setReqObj({ ...reqObj, note: e.target.value })
                        }
                      />
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col className="d-flex justify-content-end align-items-center">
                      <button
                        className="btn btn-dark me-2"
                        onClick={_ => setSendMailModal(true)}
                      >
                        Send E-Mail
                      </button>
                      <button className="btn btn-primary" onClick={handleSave}>
                        Save
                      </button>
                    </Col>
                  </Row>
                </>
              )}
            </CardBody>
          </Card>

          <ConfirmationModal
            displayMessage="Are you sure you want to send the user the welcome or decline E-Mail?"
            onAcceptClick={handleSendMail}
            show={sendMailModal}
            onCloseClick={_ => setSendMailModal(false)}
          />
        </div>
      </>
    </Modal>
  )
}

export default UserEditModal
