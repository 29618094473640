import PropTypes from "prop-types"
import React, { useState } from "react"
import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"
import { Link } from "react-router-dom"
import * as Yup from "yup"
import { Formik, Form } from "formik"

// import images
import profile from "assets/images/profile-img.png"
import logo from "assets/images/logo.svg"
import { LoginUser } from "helpers/backend_helper"
import withRouter from "components/Common/withRouter"
import { useLoading } from "helpers/custom_hooks"
import { displayMessage } from "helpers/misc_helpers"
import FormikInputField from "helpers/FormikInputField"

const Login = props => {
  const [error, setError] = useState()
  const setLoading = useLoading()

  const initialValues = {
    email: "",
    password: "",
  }

  const validationSchema = Yup.object({
    email: Yup.string().required("Please Enter Your Email"),
    password: Yup.string().required("Please Enter Your Password"),
  })

  const handleSubmit = async values => {
    try {
      setLoading(true)
      const result = await LoginUser(values)
      localStorage.setItem("authUser", result)
      props.router.navigate("/users")
    } catch (err) {
      setLoading(false)

      err?.response?.status == 400
        ? displayMessage(err.response.data, setError)
        : displayMessage("An unhandled exception happend!", setError)
    }
  }

  return (
    <React.Fragment>
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={5}>
              <Card className="overflow-hidden">
                <div className="bg-primary bg-soft">
                  <Row>
                    <Col xs={7}>
                      <div className="text-primary p-4">
                        <h5 className="text-primary">Welcome Back !</h5>
                        <p>Sign in to continue to Chips Connect.</p>
                      </div>
                    </Col>
                    <Col className="col-5 align-self-end">
                      <img src={profile} alt="" className="img-fluid" />
                    </Col>
                  </Row>
                </div>
                <CardBody className="pt-0">
                  <div>
                    <Link to="/" className="logo-light-element">
                      <div className="avatar-md profile-user-wid mb-4">
                        <span className="avatar-title rounded-circle bg-light">
                          <img
                            src={logo}
                            alt=""
                            className="rounded-circle"
                            height="34"
                          />
                        </span>
                      </div>
                    </Link>
                  </div>
                  <div className="p-2">
                    <Formik
                      initialValues={initialValues}
                      validationSchema={validationSchema}
                      onSubmit={handleSubmit}
                    >
                      {({ errors, touched }) => (
                        <Form>
                          {error ? <Alert color="danger">{error}</Alert> : null}

                          <FormikInputField
                            fieldName="email"
                            type="email"
                            label="Email"
                            errors={errors}
                            touched={touched}
                          />
                          <FormikInputField
                            fieldName="password"
                            fieldType="password"
                            label="Password"
                            errors={errors}
                            touched={touched}
                          />

                          <div className="mt-3 d-grid">
                            <button
                              className="btn btn-primary btn-block"
                              type="submit"
                            >
                              Log In
                            </button>
                          </div>
                        </Form>
                      )}
                    </Formik>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>© {new Date().getFullYear()} JUST4ONLINE e.U.</p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default withRouter(Login)

Login.propTypes = {
  history: PropTypes.object,
}
